var mainMenu = document.getElementById('mainMenu');
var toggleBtn = document.getElementById('toggleBtn');
var body = document.body;
var nav = document.getElementById('nav');
var closeMenu = document.querySelector('.close-menu');
var nav_overlay = document.querySelector('.nav-overlay');

const images = document.querySelectorAll(".img-responsive");
image_reload();

window.scrollTo(0, 0);

window.addEventListener('resize', function (event) {
  image_reload();
}, true);

function image_reload() {
  if (window.innerWidth <= 768) {
    images.forEach(function (image) {
      image.style.backgroundImage = "url(" + image.getAttribute('data-src-mobile') + ")"
    });
  } else {
    images.forEach(function (image) {
      image.style.backgroundImage = "url(" + image.getAttribute('data-src-desktop') + ")"

    });
  }
}

if (toggleBtn) {
  toggleBtn.onclick = function () {
    mainMenu.classList.toggle("js-active");
    toggleBtn.classList.toggle("js-show");
    nav.classList.toggle("js-nav-isactive");
    if (mainMenu.classList.contains("js-active")) {
      document.documentElement.classList.add("overflow-scroll-is-active");
      let menuSpan = document.querySelector("#toggleBtn > div > span").getAttribute('data-labelclose');
      if (menuSpan !== null) {
        document.querySelector("#toggleBtn > div > span").innerHTML = menuSpan;
      }
    } else {
      document.documentElement.classList.remove("overflow-scroll-is-active");
      document.querySelector("#toggleBtn > div > span").innerHTML = "Menu";
    }
  };
}

if (nav_overlay) {
  nav_overlay.onclick = function () {
    mainMenu.classList.toggle("js-active");
    toggleBtn.classList.toggle("js-show");
    nav.classList.toggle("js-nav-isactive");

    // Add or remove the class on the <html> element
    if (mainMenu.classList.contains("js-active")) {
      document.documentElement.classList.add("overflow-scroll-is-active");
    } else {
      document.documentElement.classList.remove("overflow-scroll-is-active");
    }
  };

  closeMenu.onclick = function () {
    mainMenu.classList.remove("js-active");
    toggleBtn.classList.remove("js-show");
    nav.classList.remove("js-nav-isactive");

    // Remove the class on the <html> element
    document.documentElement.classList.remove("overflow-scroll-is-active");
  };
}

var has_header_overlayer = document.querySelector('.has-header-overlayer');

if (nav_overlay) {
  const scrollThreshold = 30;
  const headerWrapper = document.querySelector('.header-wrapper');

  function handleScroll() {
    const scrollY = window.scrollY || window.pageYOffset;

    if (scrollY >= scrollThreshold) {
      headerWrapper.classList.add('header-wrapper-overlay-active');
    } else {
      headerWrapper.classList.remove('header-wrapper-overlay-active');
    }
  }

  // Attach the scroll event listener
  window.addEventListener('scroll', handleScroll);
}




var searchForm = document.querySelectorAll('.searchform');
var toggleBtnSearchForm = document.getElementById('toggle-search-form');

if (toggleBtnSearchForm) {
  toggleBtnSearchForm.onclick = function () {
    searchForm.forEach((item) => {
      item.classList.toggle('js-active');
    })
    toggleBtnSearchForm.classList.toggle("js-show");
  };
}

var backButtonMobile = document.querySelectorAll('.back-button');
if (backButtonMobile) {
  backButtonMobile.forEach((backto) => {
    backto.addEventListener('click', function (e) {
      e.preventDefault();
      var subMenu = backto.parentElement.parentElement;
      if (subMenu.classList.contains('active')) {
        subMenu.classList.remove('active');
      }
    })
  });
}

if (nav_overlay) {
  nav_overlay.onclick = function () {
    searchForm.classList.toggle("js-active");
    toggleBtnSearchForm.classList.toggle("js-show");
  };

  closeMenu.onclick = function () {
    searchForm.classList.remove("js-active");
    toggleBtnSearchForm.classList.remove("js-show");
  };
}

const activeClass = 'is-active';
document.querySelectorAll('.js-accordion-trigger').forEach(trigger => {
  trigger.addEventListener('click', function () {
    const listItem = trigger.closest('.item');
    const isListItemActive = listItem.classList.contains(activeClass);

    if (isListItemActive) {
      listItem.classList.remove(activeClass);
    } else {
      const accordion = listItem.closest('.accordion');
      const activeListItems = accordion.querySelectorAll('.item.' + activeClass);

      // Remove active class from all list items in the current accordion
      activeListItems.forEach(item => {
        item.classList.remove(activeClass);
      });

      // Add active class only to the clicked list item
      listItem.classList.add(activeClass);
    }
  });
});

const isMobileDevice = window.matchMedia("(max-width: 767px)").matches;

if (isMobileDevice) {

  const menuItems = document.querySelectorAll('#menu-header-nav li.menu-item-has-children > a');

  function handleMobileDropdown(event) {
    event.preventDefault();

    const dropdown = this.nextElementSibling;

    if (dropdown.classList.contains('active')) {
      dropdown.classList.remove('active');
      setTimeout(() => {
        this.classList.add('close');
        window.location.href = this.href;
      }, 200);
    } else {
      const activeDropdown = document.querySelector('.sub-menu.active');
      if (activeDropdown) {
        this.classList.remove('close');
        // activeDropdown.classList.remove('active');
      }
      dropdown.classList.add('active');

      if (dropdown.classList.contains('dropdown-submenu')) {
        const level4 = dropdown.querySelector('.sub-menu.level-4');
        level4.classList.add('active');
      }
    }
  }
  menuItems.forEach((menuItem) => {
    menuItem.addEventListener('click', handleMobileDropdown);
  });

}

const main_banner_sliders = document.querySelectorAll(".main-banner-slider");

if (main_banner_sliders) {
  main_banner_sliders.forEach(main_banner_slider => new Splide(main_banner_slider, {
    perPage: 1,
    rewind: true,
    arrows: true,
    autoplay: false,
    pagination: false,
    breakpoints: {
      '640': {
        perPage: 1,
        gap: '1rem',
      },
      '480': {
        perPage: 1,
        gap: '1rem',
      },
    }
  }).mount());
};

const carousel = document.querySelectorAll(".carousel-items");

if (carousel) {
  carousel.forEach(carousel => new Splide(carousel, {
    perPage: 3,
    gap: '3.5rem',
    rewind: true,
    arrows: true,
    autoplay: true,
    pagination: false,
    perMove: 1,

    breakpoints: {
      '820': {
        perPage: 2,
      },

      '480': {
        perPage: 1,
        gap: '1rem',
      },
    }
  }).mount());
};

const collage = document.querySelectorAll(".collage-items");
if (collage) {
  collage.forEach(collage => new Splide(collage, {
    perPage: 3,
    gap: '2rem',
    rewind: true,
    arrows: false,
    autoplay: false,

    breakpoints: {
      '600': {
        perPage: 1,
        gap: '1rem',
        pagination: true,
      },
    }
  }).mount());
};


const image_wrapper_splides = document.querySelectorAll(".image-wrapper-splide");

if (image_wrapper_splides) {
  image_wrapper_splides.forEach(image_wrapper_splide => new Splide(image_wrapper_splide, {
    perPage: 1,
    rewind: true,
    arrows: false,
    autoplay: true,
    pagination: true,
  }).mount());
};

const usp_items = document.querySelector(".page-main .usp-items");

if (usp_items) {
  new Splide(usp_items, {
    perPage: 3,
    rewind: true,
    arrows: false,
    autoplay: true,
    pagination: false,
    breakpoints: {
      '820': {
        perPage: 1,
      },

      '480': {
        perPage: 1,

      },
    }
  }).mount();
};



const product_detail_main_image = document.querySelector(".product-detail-main-image");
const product_detail_thumbnail_image = document.querySelector(".product-detail-thumbnail-image");

if (product_detail_main_image) {
  // new Splide( '.product-detail-main-image', {
  //     perPage: 1,
  //     rewind : true,
  //     arrows: true,
  //     autoplay: true,
  //     pagination: false,
  // } ).mount();
  var main = new Splide('.product-detail-main-image', {
    perPage: 1,
    rewind: true,
    arrows: true,
    autoplay: true,
    pagination: false,
  });

  var thumbnails = new Splide('.product-detail-thumbnail-image', {
    // rewind          : true,
    // fixedWidth      : 87,
    // fixedHeight     : 87,
    // isNavigation    : true,
    arrows: false,
    // gap             : 8,
    // pagination      : false,
    // cover           : true,
    width: 87,
    height: 470,
    direction: "ttb",
    gap: 8,
    isNavigation: true,
    fixedWidth: 87,
    fixedHeight: 87,
    pagination: false,
    cover: true,
    // dragMinThreshold: {
    //   mouse: 4,
    //   touch: 10,
    // },
    breakpoints: {
      767: {
        direction: "ltr",
        fixedWidth: 72,
        fixedHeight: 72,
        gap: 8,
        width: '100%',
        height: 257,
      },
    },
  });

  main.sync(thumbnails);
  main.mount();
  thumbnails.mount();
};

const usp_items_header = document.querySelector(".header-wrapper-top .usp-items");

if (usp_items_header) {
  new Splide(usp_items_header, {
    perPage: 1,
    rewind: true,
    arrows: false,
    autoplay: true,
    pagination: false,

  }).mount();
};

const columns_slider_1 = document.querySelectorAll(".splide-1");
const columns_slider_2 = document.querySelectorAll(".splide-2");
const columns_slider_3 = document.querySelectorAll(".splide-3");
const columns_slider_4 = document.querySelectorAll(".splide-4");
const columns_slider_5 = document.querySelectorAll(".splide-5");

if (columns_slider_5) {
  columns_slider_5.forEach(columns_slider_item => new Splide(columns_slider_item, {
    perPage: 5,
    gap: '5rem',
    rewind: true,
    arrows: false,
    autoplay: false,
    pagination: false,
    breakpoints: {
      '1480': {
        perPage: 4,
        arrows: true,
        gap: '2.5rem',
      },
      '1024': {
        perPage: 3,
        arrows: true,
        gap: '2rem',
      },
      '768': {
        perPage: 2,
        arrows: true,
        gap: '2.5rem',
      },

      '480': {
        perPage: 1,
        arrows: true,
        gap: 0,
      },
    }
  }).mount());

};

if (columns_slider_4) {
  columns_slider_4.forEach(columns_slider_item => new Splide(columns_slider_item, {
    perPage: 4,
    gap: '5rem',
    rewind: true,
    arrows: false,
    autoplay: false,
    pagination: false,
    breakpoints: {
      '1024': {
        perPage: 3,
        arrows: true,
        gap: '2.5rem',
      },
      '768': {
        perPage: 2,
        arrows: true,
        gap: '2.5rem',
      },

      '480': {
        perPage: 1,
        arrows: true,
        gap: '2rem',
      },
    }
  }).mount());
};

if (columns_slider_3) {
  columns_slider_3.forEach(columns_slider_item => new Splide(columns_slider_item, {
    perPage: 3,
    gap: '3.5rem',
    rewind: true,
    arrows: true,
    autoplay: false,
    pagination: false,
    breakpoints: {
      '1025': {
        perPage: 2,
        arrows: true,
        gap: '2.5rem',
      },

      '480': {
        perPage: 1,
        arrows: true,
        gap: '2rem',
      },
    }
  }).mount());
};

if (columns_slider_2) {
  columns_slider_2.forEach(columns_slider_item => new Splide(columns_slider_item, {
    perPage: 2,
    gap: '5rem',
    rewind: true,
    arrows: true,
    autoplay: false,
    pagination: false,
    breakpoints: {

      '480': {
        perPage: 1,
        arrows: true,
        gap: '0',
      },
    }
  }).mount());
};

if (columns_slider_1) {
  columns_slider_1.forEach(columns_slider_item => new Splide(columns_slider_item, {
    perPage: 1,
    gap: '0rem',
    rewind: true,
    arrows: true,
    autoplay: false,
    pagination: false,
  }).mount());
};

var js_scroll_to_top = document.querySelector("#js-scroll-to-top");
var main_banner = document.querySelector('#scroll-to-banner-items-top');

if (js_scroll_to_top) {
  js_scroll_to_top.addEventListener('click', function (e) {
    e.preventDefault();

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: main_banner.offsetTop - 75
    });
  });
}


function initializeStickyBlock(selector, stickyId, productDetailId) {
  const stickyBlock = document.querySelector(selector);

  if (stickyBlock) {
    const stickyElement = document.getElementById(stickyId);
    const productDetailRightBottom = document.getElementById(productDetailId);
    const headerWrapper = document.querySelector('.header-wrapper');

    if (!stickyElement || !productDetailRightBottom) return;

    // Calculate offsetBottom for the sticky element
    const topPos = productDetailRightBottom.offsetTop + productDetailRightBottom.offsetHeight;

    // Attach scroll event listener
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > topPos) {
        stickyElement.classList.add("fixed");
        headerWrapper.classList.add("shadow-none");
      } else {
        stickyElement.classList.remove("fixed");
        headerWrapper.classList.remove("shadow-none");
      }
    });
  }
}


function addActiveClassAfterClick(selector) {
  const stickyLinks = document.querySelectorAll(selector);

  if (stickyLinks.length) {
    stickyLinks.forEach((link) => {
      link.addEventListener("click", function () {
        // Remove active class from all links
        stickyLinks.forEach((item) => item.classList.remove("active"));

        // Add active class to the clicked link
        this.classList.add("active");
      });
    });
  }
}

const sections = [
  "#technical",
  "#description",
  "#downloads",
  "#references",
  "#news",
];
function addActiveClassOnScroll(tocSelector, sections) {
  const stickyLinks = document.querySelectorAll(tocSelector);
  const sectionElements = sections.map((id) => document.querySelector(id));

  if (!stickyLinks.length || !sectionElements.length) return;

  const observerOptions = {
    root: null, 
    rootMargin: "-183px 0px -92px 0px",
    threshold: 0.6, 
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Find the corresponding link
        stickyLinks.forEach((link) => {
          const targetId = link.getAttribute("href");
          if (targetId === `#${entry.target.id}`) {
            link.classList.add("active");
          } else {
            link.classList.remove("active");
          }
        });
      }
    });
  }, observerOptions);

  // Observe each section
  sectionElements.forEach((section) => {
    if (section) observer.observe(section);
  });
}

// Initialize sticky blocks separately
initializeStickyBlock(".sticky-contact-block", "js-sticky-contact", "js-product-detail-sticky");
initializeStickyBlock(".sticky-toc-block", "js-sticky-toc", "js-product-detail-sticky");

addActiveClassAfterClick(".sticky-toc-block .col-wrap a");
addActiveClassOnScroll(".sticky-toc-block .col-wrap a", sections);

var downloadFormLinks = document.querySelectorAll(".download-form");
var closeButton = document.querySelectorAll(".close-form");
var hiddenDiv = document.querySelectorAll(".download-form-wrapper");
var textField = document.querySelectorAll(".filefield input");

for (var i = 0; i < downloadFormLinks.length; i++) {
  downloadFormLinks[i].addEventListener("click", function (e) {
    e.preventDefault();
    var formId = this.getAttribute("data-form");
    for (var j = 0; j < hiddenDiv.length; j++) {
      if (hiddenDiv[j].classList.contains('hidden-' + formId)) {
        hiddenDiv[j].classList.remove("hidden");
        var dataFile = this.getAttribute("data-file");
        var currentTextField = hiddenDiv[j].querySelector('.filefield input');
        currentTextField.value = dataFile;

        var dataName = this.getAttribute("data-name");
        var currentDataNameField = hiddenDiv[j].querySelector('.filename input');
        var currentProductTitle = hiddenDiv[j].querySelector('.product-title-pop');
        currentDataNameField.value = dataName;
        if (currentProductTitle != null) {
          currentProductTitle.textContent = dataName;
        }

      }
    }
  });
}

if (closeButton.length > 0) {
  for (var i = 0; i < closeButton.length; i++) {
    closeButton[i].addEventListener("click", function (e) {
      e.preventDefault();
      for (var j = 0; j < textField.length; j++) {
        textField[j].value = "";
      }
      for (var j = 0; j < hiddenDiv.length; j++) {
        if (!hiddenDiv[j].classList.contains('hidden')) {
          hiddenDiv[j].classList.add('hidden');
        }
      }
      var currentCloseButton = this;
      if (currentCloseButton.nextElementSibling !== null) {
        var confirmationBox = currentCloseButton.nextElementSibling.firstElementChild;
        if (confirmationBox && confirmationBox.classList.contains('wpforms-confirmation-container-full')) {
          location.reload();
        }
      }
    });
  }
}
document.addEventListener("DOMContentLoaded", function () {
  var elementsArray = document.querySelectorAll('[id^="wpforms-form-"]');

  elementsArray.forEach(function (elem) {

    elem.addEventListener("submit", function (e) {

      var observer = new MutationObserver(function (mutations, obs) {
        mutations.forEach(function (mutation) {
          mutation.addedNodes.forEach(function (node) {
            if (node.nodeType === 1 && node.classList.contains('wpforms-confirmation-container-full')) {
              var hiddenField = node.querySelector('.wpforms-entry[type="hidden"]');
              if (hiddenField) {
                var entryId = hiddenField.value;

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "wpFormSubmit",
                  entryId: entryId,
                  wpFormElement: elem
                });

              }

              obs.disconnect();
            }
          });
        });
      });


      observer.observe(elem.parentNode, {
        childList: true,
        subtree: true
      });
    });
  });
});


if (window.innerWidth >= 768) {

  const items = document.querySelectorAll('.intro-page-items .item');
  const introPageItems = document.querySelector('.intro-page-items');

  function addPreloadClass() {

    if (items.length > 0) {
      document.querySelector('.item-1').classList.add('preload-item');
      document.querySelector('.item-3').classList.add('preload-item');
      introPageItems.classList.add('preload-animation');
      setTimeout(applyOpacityWithStagger, 500);
    }
  }


  function removeIntroPagePreloadClass() {
    if (items.length > 0) {
      introPageItems.classList.remove('preload-animation');
    }
  }

  function removePreloadClass() {

    if (items.length > 0) {
      document.querySelector('.item-1').classList.remove('preload-item');
      document.querySelector('.item-3').classList.remove('preload-item');

      setTimeout(removeIntroPagePreloadClass, 500);
    }
  }


  function applyOpacityWithStagger() {
    items.forEach((item, index) => {
      const delay = index * 300;
      setTimeout(() => {
        item.style.opacity = 1;
      }, delay);
    });
  }

  addPreloadClass();

  setTimeout(removePreloadClass, 2000);

  items.forEach((item) => {
    item.addEventListener('mouseenter', () => {
      const activeItem = document.querySelector('.item.is-active');
      if (activeItem) {
        activeItem.classList.remove('is-active');
      }
      item.classList.add('is-active');
    });
  });
}

if (window.innerWidth <= 768) {
  const bullets = document.querySelectorAll('.bullet');
  const items = document.querySelectorAll('.item');
  let touchStartX = 0;
  let touchEndX = 0;

  function handleSwipe() {
    const swipeThreshold = 50;
    if (touchEndX < touchStartX - swipeThreshold) {

      const activeBullet = document.querySelector('.bullet.active');
      const slide = activeBullet.getAttribute('data-slide');
      if (slide < bullets.length) {
        bullets[slide].click();
      }
    } else if (touchEndX > touchStartX + swipeThreshold) {

      const activeBullet = document.querySelector('.bullet.active');
      const slide = activeBullet.getAttribute('data-slide');
      if (slide > 1) {
        bullets[slide - 2].click();
      }
    }
  }


  function applyOpacityWithStaggerToLetters() {
    const letters = document.querySelectorAll('.letters.mobile-only span');
    letters.forEach((letter, index) => {
      const delay = index * 500;
      setTimeout(() => {
        letter.style.opacity = 1;
      }, delay);
    });
  }

  items.forEach((item) => {
    item.addEventListener('touchstart', (e) => {
      touchStartX = e.touches[0].clientX;
    });

    item.addEventListener('touchend', (e) => {
      touchEndX = e.changedTouches[0].clientX;
      handleSwipe();
    });
  });


  bullets.forEach((bullet) => {
    bullet.addEventListener('click', () => {
      const slide = bullet.getAttribute('data-slide');
      items.forEach((item) => {
        item.classList.remove('is-active');
      });
      document.querySelector(`.item-${slide}`).classList.add('is-active');
      bullets.forEach((b) => {
        b.classList.remove('active');
      });
      bullet.classList.add('active');
    });
  });

  applyOpacityWithStaggerToLetters();

  setTimeout(() => {
    const item1 = document.querySelector('.item-1.is-active');
    if (item1) {
      item1.classList.remove('bg-dark');
    }
  }, 2000);

  setTimeout(() => {
    const wrap = document.querySelector('.wrap');
    if (wrap) {
      wrap.style.opacity = 1;
    }
  }, 2000);
}

const carousel_slider = document.querySelectorAll(".carousel-slider");
if (carousel_slider) {
  carousel_slider.forEach(columns_slider_item => new Splide(columns_slider_item, {
    perPage: 5,
    gap: '3rem',
    rewind: true,
    arrows: true,
    autoplay: false,
    pagination: true,
    breakpoints: {
      '1480': {
        perPage: 4,
        arrows: true,
        gap: '2.5rem',
      },
      '1024': {
        perPage: 3,
        arrows: true,
        gap: '2rem',
        pagination: false,
      },
      '768': {
        perPage: 2,
        arrows: true,
        gap: '1rem',
      },

      '480': {
        perPage: 1,
        arrows: true,
        gap: 0,
      },
    }
  }).mount());

};


if (document.querySelector('.form-wrapper-pdp .show-desktop-hide-mobile .wpforms-title') !== null) {
  if (document.querySelector('[name="wpforms[fields][12]"]') !== null) {
    let prodName = document.querySelector('[name="wpforms[fields][12]"]')?.value
    document.querySelector('.form-wrapper-pdp .show-desktop-hide-mobile .wpforms-title').innerHTML += ` <span class="form-product-name">(${prodName})</span>`
  }
}

if (document.querySelector('.hide-desktop-show-mobile .form-wrapper-pdp .wpforms-title') !== null) {
  if (document.querySelector('[name="wpforms[fields][12]"]') !== null) {
    let prodName = document.querySelector('[name="wpforms[fields][12]"]')?.value
    document.querySelector('.hide-desktop-show-mobile .form-wrapper-pdp .wpforms-title').innerHTML += ` <span class="form-product-name">(${prodName})</span>`
  }
}

